<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-img
          if="imageFood"
          ref="imageFood"
          :src="data.Image"
          contain
          aspect-ratio="1"
          v-on="on"
          style="width: 100px;padding: 0 10px"
        />
        <div
          style="position:absolute; top:10px;left:10px;opacity:0.5;"
        >
          <v-icon color="white">mdi-arrow-expand</v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text style="padding: 10px 0px 0px 0px;">
          <div style="width:100%;text-align: right;padding: 0 40px">
            <button 
            style="margin-right:-30px;margin-top:-10px;"
              @click="dialog = false"
            >
              <v-icon
                style="margin-left: 0px; color: white;font-size:30px;"
                :style="{ 'color': btnBackgroundColor }"
                class="activity-button"
              >
                mdi-close
              </v-icon>
            </button>
          </div>
          <center style="margin-bottom: 20px;">
            <button 
            disabled="true"
            style="border-radius:50%;height: 55px;width:55px;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;"
            :style="{ 'background-color': btnBackgroundColor}"
          >
            <v-icon
              style="margin-left: 0px; color: white;font-size:35px;"
              dark
              class="activity-button"
              :style="{ 'color': btnTextColor}"
            >
              mdi-camera
            </v-icon>
          </button>
          </center>
          <center
            :style="{ 'color': foodDrinkTextColor,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{name}}
          </center>
          <center
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent - 5}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{ingredients}}
          </center>
          <center 
            style="margin-top:5px;"
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
          >
            {{data.Price}}
          </center>
          <center style="margin-top:20px;">
            <img
              :src="data.Image"
              width="100%"
              heigth="auto"
              alt="item.Name"
              style="margin-bottom: -6px;"
            > 
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      locale: {
        type: String,
        required: true
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        dialog: false,
        name: '',
        ingredients: '',
        iconWidth: null
      }
    },
    computed: {
      BGColor () {
        return this.config.BGColor
      },
      FontContent () {
        return this.config.FontContent
      },
      FontSizeContent () {
        return this.config.FontSizeContent
      },
      ColorContent () {
        return this.config.ColorContent
      },
      enterpriseColor () {
        return this.config.EnterpriseColor
      },
      foodDrinkTextColor () {
        return this.config.FoodDrinkTextColor && this.config.FoodDrinkTextColor !== '' ? this.config.FoodDrinkTextColor : this.config.ColorContent
      },
      foodDrinkBGColor () {
        return this.config.FoodDrinkBGColor && this.config.FoodDrinkBGColor !== '' ? this.config.FoodDrinkBGColor : 'white'//'#000000'
      },

      btnBackgroundColor () {        
        return this.config.BtnBackgroundColor && this.config.BtnBackgroundColor !== '' ? this.config.BtnBackgroundColor : this.config.BGColor
      },
      btnTextColor () {
        return this.config.BtnTextColor && this.config.BtnTextColor !== '' ? this.config.BtnTextColor : 'white'
      },
    },
    watch: {
      locale (v) {
        this.name = this.data.Name ? utils.getTranslation (this.data.Name, v) : ''
        this.ingredients = this.data.Ingredients ? utils.getTranslation (this.data.Ingredients, v) : ''    
      },
    },
    mounted () {
      this.name = this.data.Name ? utils.getTranslation (this.data.Name, this.locale) : ''
      this.ingredients = this.data.Ingredients ? utils.getTranslation (this.data.Ingredients, this.locale) : ''
    },
  }
</script>

<style scoped>
  .modal-allergens {
    background-color: rgba(241, 241, 241);
  }
</style>
